import { FC, useContext, useEffect } from "react";

import { useEnterPressable } from "../../utils/use-enter-pressable";

import { ModalContext } from "./moda-context";

import "./modal.css";

const Modal: FC = () => {
  const { children, visible, hideModal, width, height } =
    useContext(ModalContext);

  const onEnterPress = useEnterPressable(hideModal);

  useEffect(() => {
    const listener = (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        hideModal();
      }
    };

    document.addEventListener("keyup", listener);

    return () => document.removeEventListener("keyup", listener);
  }, [hideModal]);

  return (
    <div>
      <div
        style={{
          width,
          height,
          top: `calc(50vh - ${height / 2}px)`,
          left: `calc(50vw - ${width / 2}px)`,
        }}
        className={`modal-content ${
          visible ? "modal-content-visible" : "modal-content-hidden"
        }`}
      >
        <button onClick={hideModal} className="close-button" type="button">
          x
        </button>
        {children}
      </div>
      <div
        aria-label="modal overlay close modal"
        tabIndex={0}
        role="button"
        onKeyPress={onEnterPress}
        onClick={hideModal}
        className={`modal-overlay ${
          visible ? "modal-overlay-visible" : "modal-overlay-hidden"
        }`}
      />
    </div>
  );
};

export { Modal };
