import { FC } from "react";
import "./nav.css";

type Props = {
  visible: boolean;
  siteTitle: string;
  hide: () => void;
};

const Nav: FC<Props> = ({ visible, siteTitle, hide }) => {
  return (
    <div
      className={`main-nav ${visible ? "main-nav-visible" : "main-nav-hidden"}`}
    >
      <h2>{siteTitle}</h2>
      <nav>
        <ul>
          <li aria-hidden onClick={hide}>
            <a href="#projects">Home</a>
          </li>
          <li>
            <a aria-hidden onClick={hide} href="/">
              About
            </a>
          </li>
          <li>
            <a aria-hidden onClick={hide} href="/">
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Nav };
