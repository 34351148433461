/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import { FC, ReactNode, useState } from "react";

import { Header } from "../../components/header/header";
import { Modal } from "../../components/modal/modal";
import { Nav } from "../../components/nav/nav";
import "./layout.css";

type Props = {
  children: ReactNode;
};

type DataProps = {
  allMetaDataJson: {
    edges: { node: { siteMetaData: { title: string; shortTitle: string } } }[];
  };
};

const Layout: FC<Props> = ({ children }) => {
  const data: DataProps = useStaticQuery(graphql`
    query SiteTitleQuery {
      allMetaDataJson {
        edges {
          node {
            id
            siteMetaData {
              title
              shortTitle
              description
              author
              siteUrl
            }
          }
        }
      }
    }
  `);

  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => setVisible((prev) => !prev);

  const siteShortTitle =
    data.allMetaDataJson.edges[0].node.siteMetaData.shortTitle || `Title`;
  const siteTitle =
    data.allMetaDataJson.edges[0].node.siteMetaData.title || `Title`;

  return (
    <>
      <Modal />
      <div className="header-nav">
        <Header
          toggleNav={toggleVisibility}
          navVisible={visible}
          siteTitle={siteShortTitle}
        />
        <Nav
          hide={() => setVisible(false)}
          siteTitle={siteTitle}
          visible={visible}
        />
      </div>
      <div id="projects" aria-hidden onClick={() => setVisible(false)}>
        <main>{children}</main>
      </div>
    </>
  );
};

export { Layout };
