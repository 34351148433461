/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import { FC } from "react";
import { Helmet } from "react-helmet";

type Props = {
  description?: string;
  lang?: string;
  meta?: ConcatArray<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | { name: string; content: any; property?: undefined }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | { property: string; content: any; name?: undefined }
  >;
  title: string;
};

const Seo: FC<Props> = ({
  description = "",
  lang = "pt",
  meta = [],
  title,
}) => {
  const data = useStaticQuery(
    graphql`
      query SiteTitle {
        allMetaDataJson {
          edges {
            node {
              id
              siteMetaData {
                title
                shortTitle
                description
                author
                siteUrl
              }
            }
          }
        }
      }
    `
  );

  const {
    description: siteDescription,
    title: siteTitle,
    author,
  } = data.allMetaDataJson.edges[0].node.siteMetaData;

  const metaDescription = description || siteDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={siteTitle ? `%s | ${siteTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export { Seo };
