import { Link } from "gatsby";
import { FC } from "react";

import { useEnterPressable } from "../../utils/use-enter-pressable";

import "./header.css";

type Props = {
  siteTitle: string;
  toggleNav: () => void;
  navVisible: boolean;
};

const Header: FC<Props> = ({ siteTitle, toggleNav, navVisible }) => {
  const toggleNavOnPress = useEnterPressable(toggleNav);

  return (
    <header>
      <h1 className={navVisible ? "hidden" : "visible"}>
        <Link to="/">{siteTitle}</Link>
      </h1>
      <div
        className={`hamburguer-wrapper ${
          navVisible ? "hamburguer-wrapper-close" : ""
        }`}
      >
        <div
          style={{ outline: "none" }}
          tabIndex={0}
          role="button"
          onClick={toggleNav}
          onKeyDown={toggleNavOnPress}
        >
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </header>
  );
};

export { Header };
